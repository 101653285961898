import Header from '../components/header/header.component'
import Footer from '../components/footer/footer.component';

function Covid() {
  return (
    <div className="covid">
    <Header />      
    <h2>Covid</h2>
    <Footer/>
  </div>
  );
}

export default Covid;