import HomepageHeader from '../components/homepage-header/homepage-header.component'
import HomepageAnimation from '../components/homepage-animation/homepage-animation.component'
import Footer from '../components/footer/footer.component';

function Home() {
  return (  
    <div className="Home">
      <HomepageHeader />
      <HomepageAnimation />
      <Footer/>
    </div>
  );
}

export default Home;
