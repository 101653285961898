import Header from '../components/header/header.component'
import Footer from '../components/footer/footer.component';

function Startups() {
  return (
    <div className="startups">
      <Header />      
      <h2>Startups</h2>
      <Footer/>
  </div>
  );
}

export default Startups;