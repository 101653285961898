import './App.css';

import { 
  BrowserRouter,
  Routes,
  Route
 } from "react-router-dom";

import Home from './routes/home.jsx'
import Covid from './routes/covid.jsx';
import Startups from './routes/startups.jsx';
import About from './routes/about.jsx';

function App() {
  return (  
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/startups" element={<Startups />}> </Route>
          <Route path="/covid" element={<Covid />}></Route>
          <Route path="/about" element={<About />}></Route>      
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
