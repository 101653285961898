import { Component } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import './homepage-animation.component.css'

class HomepageAnimation extends Component {

  render() {
    return (
      <div id="homepage-animation">
        <Player autoplay loop 
          src="animations/spaceman-rocket.json"
          style={{ height: '70vh', width: '100vw' }}>
        </Player>
        <div className="website-name">Burhum.com</div>
    </div>
    )
  }
}

export default HomepageAnimation;