import Header from '../components/header/header.component'
import Footer from '../components/footer/footer.component';

function About() {
  return (
    <div className="about">
    <Header />      
    <h2>About</h2>
    <Footer/>
  </div>
  );
}

export default About;