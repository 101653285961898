import { Component } from 'react';
import { Link } from "react-router-dom";

import './header.component.scss'

class Header extends Component {
  render() {
    return (
      <div id="header">        
        <nav
          style={{
            borderBottom: "solid 1px",
            paddingBottom: "1rem",
            color: "white"
          }}>
          <Link to="/">Home</Link> |{" "}
          <Link to="/startups">Startups</Link> |{" "}
          <Link to="/covid">Covid</Link> |{" "}
          <Link to="/about">Sobre Mi</Link>
        </nav>      
    </div>
    )
  }
}

export default Header;