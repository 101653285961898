import { Component } from 'react';

import './homepage-header.component.scss'

class HomepageHeader extends Component {
  render() {
    return (
      <div id="homepage-header">
	{/*
        <div className="homepage-btn-container">
          <button className="home-btn">Sobre Ragi</button>
          <button className="home-btn">Startups</button>
          <button className="home-btn">Covid</button>
        </div>
  	*/}
      </div>
    )
  }
}
export default HomepageHeader;

/*
import { Link } from "react-router-dom";
<Link to="/about">
<Link to="/startups">
<Link to="/covid">
*/
